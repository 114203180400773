import React from 'react';
import Select from 'react-select';
import { Field } from 'formik';

const customStyles = {
  control: provided => ({
    ...provided,
    height: '48px',
    borderColor: '#dadada',
    borderRadius: 3,
    color: '#b0b0b0',
  }),
  menu: provided => ({
    ...provided,
    zIndex: 2,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : '#292a2a',
    fontSize: '1rem',
    backgroundColor: state.isSelected ? '#0053a5' : 'initial',
    zIndex: 2,
  }),
  placeholder: provided => ({
    ...provided,
    color: '#b0b0b0',
    fontSize: '1rem',
  }),
  singleValue: provided => ({
    ...provided,
    fontSize: '1rem',
  }),
};

export default ({
  name,
  placeholder,
  data,
  onChangeHandler = () => {},
  CustomOptionComponent,
  ariaLabel,
  onKeyDown,
}) => {
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        return (
          <div>
            <Select
              className={`custom-select-input ${
                meta.touched && meta.error ? 'error' : ''
              }`}
              classNamePrefix="custom-select"
              name={name}
              value={data.filter(option => option.value === field.value)}
              options={data}
              placeholder={placeholder}
              styles={customStyles}
              onChange={data => {
                onChangeHandler(data.value);
                form.setFieldValue(name, data.value);
              }}
              components={
                CustomOptionComponent ? { Option: CustomOptionComponent } : null
              }
              aria-label={ariaLabel}
              onKeyDown={onKeyDown}
            />
            {meta.touched && meta.error && (
              <p className="error-message">{meta.error}</p>
            )}
          </div>
        );
      }}
    </Field>
  );
};
